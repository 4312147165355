var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "card",
        { attrs: { title: _vm.$t("rbac.title") } },
        [
          _vm.rbac
            ? _c("v-simple-table", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("thead", [
                            _c(
                              "tr",
                              [
                                _c("th", [_vm._v("Permission")]),
                                _vm._l(_vm.rbac.roles, function (role) {
                                  return _c(
                                    "th",
                                    {
                                      key: role.name,
                                      staticClass: "text-left",
                                    },
                                    [_vm._v(" " + _vm._s(role.name)), _c("br")]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.rbac.permissions, function (permission) {
                              return _c(
                                "tr",
                                { key: permission.name },
                                [
                                  _c("td", [
                                    _c(
                                      "code",
                                      { staticClass: "primary--text" },
                                      [_vm._v(_vm._s(permission.name))]
                                    ),
                                    _c("br"),
                                    _c("small", [
                                      _c("i", [
                                        _vm._v(_vm._s(permission.description)),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._l(_vm.rbac.roles, function (role) {
                                    return _c(
                                      "td",
                                      { key: role.name },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              disabled: role.name === "admin",
                                              small: "",
                                              color: _vm.hasPermission(
                                                role.name,
                                                permission.name
                                              )
                                                ? "green"
                                                : "red",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.togglePermission(
                                                  role.name,
                                                  permission.name
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "fa fa-" +
                                                _vm._s(
                                                  _vm.hasPermission(
                                                    role.name,
                                                    permission.name
                                                  )
                                                    ? "check"
                                                    : "times"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1650688133
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }